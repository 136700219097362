import React from "react";
import PropTypes from "prop-types";
import NextImage from "next/image";
import { twMerge } from "tailwind-merge";

const IMAGE_LOADING_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  LOADED: "loaded",
  ERROR: "error",
};

const PLACEHOLDER_DISPLAY_DELAY = 300;

// This SVG shimmer effect mimics the 'animate-pulse' effect in Tailwind CSS.
const Shimmer = () => `
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="100%" height="100%" fill="#71717a">
      <animate attributeName="opacity" begin="0s" values="1; 0.5; 1" keyTimes="0; 0.5; 1" dur="2s" repeatCount="indefinite" calcMode="spline" keySplines="0.4 0 0.6 1; 0.4 0 0.6 1"/>
    </rect>
  </svg>`;

const toBase64 = (str) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

const placeholder = `data:image/svg+xml;base64,${toBase64(Shimmer())}`;

export default function TrophyAvatar({
  src,
  fallback,
  className,
  imageProps = {},
}) {
  const [showPlaceholder, setShowPlaceholder] = React.useState(false);
  const [imageLoadingStatus, setImageLoadingStatus] = React.useState(
    IMAGE_LOADING_STATUS.IDLE,
  );

  const renderFallback =
    imageLoadingStatus === IMAGE_LOADING_STATUS.ERROR && fallback;

  React.useEffect(() => {
    let timer;
    if (imageLoadingStatus === IMAGE_LOADING_STATUS.LOADING) {
      timer = setTimeout(
        () => setShowPlaceholder(true),
        PLACEHOLDER_DISPLAY_DELAY,
      );
    } else {
      setShowPlaceholder(false);
    }
    return () => clearTimeout(timer);
  }, [imageLoadingStatus]);

  const onImageLoadingStatusChange = React.useCallback((status) => {
    setImageLoadingStatus(status);
  }, []);

  return (
    <span
      className={twMerge(
        "rounded-full overflow-hidden block",
        renderFallback &&
          "bg-gray-900 flex items-center justify-center select-none",
        className,
      )}
      style={{ position: "relative" }}
    >
      {renderFallback ? (
        <AvatarFallback
          imageLoadingStatus={imageLoadingStatus}
          fallback={fallback}
        />
      ) : (
        <AvatarImage
          src={src}
          imageLoadingStatus={imageLoadingStatus}
          onImageLoadingStatusChange={onImageLoadingStatusChange}
          imageProps={imageProps}
          showPlaceholder={showPlaceholder}
        />
      )}
    </span>
  );
}

function AvatarImage({
  src,
  imageLoadingStatus,
  onImageLoadingStatusChange,
  imageProps,
  showPlaceholder,
}) {
  React.useEffect(() => {
    if (src) {
      onImageLoadingStatusChange(IMAGE_LOADING_STATUS.LOADING);
    } else {
      onImageLoadingStatusChange(IMAGE_LOADING_STATUS.ERROR);
    }
  }, [src, onImageLoadingStatusChange]);

  const handleImageError = React.useCallback(() => {
    onImageLoadingStatusChange(IMAGE_LOADING_STATUS.ERROR);
  }, [onImageLoadingStatusChange]);

  const handleImageLoad = React.useCallback(() => {
    onImageLoadingStatusChange(IMAGE_LOADING_STATUS.LOADED);
  }, [onImageLoadingStatusChange]);

  if (!src || imageLoadingStatus === IMAGE_LOADING_STATUS.ERROR) {
    return null;
  }

  return (
    <NextImage
      src={src}
      fill={true}
      className="h-full w-full rounded-[inherit]"
      placeholder={showPlaceholder ? placeholder : "empty"}
      alt=""
      onLoad={handleImageLoad}
      onError={handleImageError}
      {...imageProps}
    />
  );
}

function AvatarFallback({ fallback }) {
  return (
    <span className="flex h-full w-full bg-gray-500 items-center justify-center text-md text-white font-medium">
      {fallback}
    </span>
  );
}

TrophyAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  fallback: PropTypes.node,
  className: PropTypes.string,
  imageProps: PropTypes.object,
};
